import React from 'react';
import Img from 'gatsby-image';
import { FormattedMessage, Link as LinkIntl } from 'gatsby-plugin-intl';
import { Button, Col, Container, Row } from 'react-bootstrap';

import './AboutSummary.css';

const AboutSummary = (props) => (
  <section id="about" className="section-content">
    <Container fluid>
      <h2 className="section-headline" dangerouslySetInnerHTML={{ __html: props.content.usp.headline }} />
      {props.content?.usp?.text && (
        <p className="section-headline-small" dangerouslySetInnerHTML={{ __html: props.content.usp.text }} />
      )}
      <div className="about-summary-row">
        <Row className="mt-4">
          {props.content.usp.elements.map((element, index) => (
            <Col
              key={`about-summary-element-${index}`}
              xs={12}
              lg={4}
              className="about-summary-element"
              style={{ marginBottom: index === props.content.usp.elements.length - 1 && '0 !important' }}
            >
              <div className="about-summary-element-image">
                {element.image.extension === 'svg' ? (
                  <img src={element.image.publicURL} alt={element.name} className="about-summary-element-svg" />
                ) : (
                  <Img fluid={element.image.childImageSharp.fluid} alt={element.name} />
                )}
              </div>
              <div className="about-summary-element-title">
                <h3>{element.name}</h3>
              </div>
              <div className="about-summary-element-text">
                <p>{element.text}</p>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={12}>
            <div className="about-summary-button">
              <LinkIntl to="/about/index.html">
                <Button
                  size="lg"
                  variant="light"
                  style={{
                    color: 'white',
                    backgroundColor: '#009688',
                    minWidth: '230px',
                    borderRadius: '8px',
                  }}
                >
                  <FormattedMessage id="buttons.about_us" />
                </Button>
              </LinkIntl>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  </section>
);

export default AboutSummary;
