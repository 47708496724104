import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Col, Container, Row } from 'react-bootstrap';

import * as classes from './ContactOffice.module.css';

import MapsImage from '../../images/contact-office/google-maps.png';

const ContactOffice = (props) => {
  const intl = useIntl();
  return (
    <section id="office" className="section-content">
      <Container fluid>
        <h2 className="section-headline" dangerouslySetInnerHTML={{ __html: props.content.office.headline }} />
        {props.content?.office?.text && (
          <p className="section-headline-small" dangerouslySetInnerHTML={{ __html: props.content.office.text }} />
        )}
        <div className={classes.ContactOfficeRow}>
          <Row style={{ position: 'relative', zIndex: 500 }}>
            <Col xs={12} md={9} lg={10}>
              <a href={'https://goo.gl/maps/nSbqvdSjKWnUU2xN9'} target="_blank" rel="noreferrer">
                <img
                  src={MapsImage}
                  alt={'Google Maps'}
                  style={{ height: '400px', width: '100%', border: 0, objectFit: 'cover' }}
                />
              </a>
            </Col>
            <Col xs={12} md={3} lg={2} className={classes.ContactOfficeAddressContainer}>
              <div className={classes.ContactOfficeAddress}>
                <div className={classes.ContactOfficeAddressTitle}>
                  <span>Bebensee IT GmbH</span>
                </div>
                <div className={classes.ContactOfficeAddressText}>
                  <span>Marie-Curie-Straße 1</span>
                </div>
                <div className={classes.ContactOfficeAddressText}>
                  <span>26129 Oldenburg</span>
                </div>
                {intl.locale !== 'de' && (
                  <div className={classes.ContactOfficeAddressText}>
                    <span>Germany</span>
                  </div>
                )}
                <div className={classes.ContactOfficeAddressText}>
                  <span>+49 441 559760-0</span>
                </div>
              </div>
            </Col>
          </Row>
          <div className={classes.ContactOfficeMapDecoration} />
        </div>
      </Container>
    </section>
  );
};

export default ContactOffice;
