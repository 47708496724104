import React from 'react';
import PropTypes from 'prop-types';

import * as classes from './ClientItem.module.css';

const ClientItem = props => (
    <a href={props.url} target="_blank" rel="noopener noreferrer">
        <img src={props.logo} className={classes.Client} alt={props.name} />
    </a>
);

ClientItem.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired
};

export default ClientItem;
