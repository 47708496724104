import React, { useRef, useState } from 'react';
import Img from 'gatsby-image';
import { FormattedMessage, Link as LinkIntl } from 'gatsby-plugin-intl';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import bullets from '../../config/colors/bullets';

import './ServiceSummary.css';

import BackgroundTriangle from '../BackgroundTriangle/BackgroundTriangle';

const ServiceSummary = (props) => {
  const elementRef = useRef();
  const imageRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const activeService = props.content.services.elements[activeIndex];

  const clickHandler = (index) => {
    setActiveIndex(index);
    elementRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // add missing attributes from services array
  props.content.services.elements.forEach((service, index) => {
    const match = props.services.filter((element) => element.frontmatter.meta.name === service.name)[0];
    props.content.services.elements[index].slug = match.frontmatter.meta.slug;
  });

  return (
    <section id="services" className="section-content">
      <BackgroundTriangle />
      <Container id="service-summary-container" fluid>
        <h2
          className="section-headline underline"
          dangerouslySetInnerHTML={{ __html: props.content.services?.headline }}
          ref={elementRef}
        />
        {props.content?.services?.text && (
          <p className="section-headline-small" dangerouslySetInnerHTML={{ __html: props.content.services.text }} />
        )}

        <div className="service-summary-row">
          <Row>
            <Col lg={6}>
              <div className="service-summary-buttons">
                {props.content?.services?.elements?.map((element, index) => (
                  <div
                    className={index === activeIndex ? 'service-summary-button' : 'service-summary-button-collapsed'}
                    key={`service-summary-button-${index}`}
                    role="button"
                    onClick={() => clickHandler(index)}
                  >
                    <div
                      className="service-summary-button-picture"
                      style={{ background: element.background || bullets[index % 3].background }}
                    >
                      <img src={element.icon.publicURL} alt={element.name + ': ' + element.text} className="w-100" />
                    </div>
                    <div className="service-summary-button-text">
                      <div className="service-progress">
                        <div
                          className="service-progress-bar"
                          style={{ background: element.color || bullets[index % 3].color }}
                        />
                      </div>
                      <div className="service-title-wrapper">
                        <h3 dangerouslySetInnerHTML={{ __html: element.name }} />
                        {activeIndex !== index && (
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className="service-summary-title-chevron"
                            style={{ marginLeft: '1rem' }}
                          />
                        )}
                        {activeIndex === index && (
                          <FontAwesomeIcon
                            icon={faChevronUp}
                            className="service-summary-title-chevron"
                            style={{ marginLeft: '1rem' }}
                          />
                        )}
                      </div>
                      <p dangerouslySetInnerHTML={{ __html: element.text }} />
                      {index === activeIndex && (
                        <LinkIntl to={`/services/${element.slug}/index.html`}>
                          <Button variant="link" className="service-summary-button-link">
                            <FormattedMessage id="buttons.learn_more" />
                            <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '.75rem' }} />
                          </Button>
                        </LinkIntl>
                      )}
                      <Img
                        fluid={element.image.childImageSharp.fluid}
                        alt={element.name}
                        ref={imageRef}
                        className="w-100 mt-3 d-lg-none service-portfolio-image"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col lg={6} className="d-xs-none d-sm-none d-md-none d-lg-block m-auto">
              <Img
                className="w-100 service-portfolio-image"
                fluid={activeService.image.childImageSharp.fluid}
                alt={activeService.name}
                style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', height: '100%' }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default ServiceSummary;
