import React, { useState, useRef, useEffect } from 'react';
import Img from 'gatsby-image';
import { Container } from 'react-bootstrap';

import bullets from '../../config/colors/bullets';

import './TestimonialsSummary.css';

const TestimonialsSummary = (props) => {
  const containerRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(1100);

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(containerRef.current.offsetWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef, setContainerWidth]);

  const containerStyle = {
    transform: `translateX(-${activeIndex * containerWidth}px)`,
  };

  const clickHandler = (index) => {
    setActiveIndex(index);
  };

  return (
    <section id="testimonials" className="section-content">
      <Container id="testimonials-summary-container" fluid>
        <h2 className="section-headline" dangerouslySetInnerHTML={{ __html: props.content.testimonials.headline }} />
        {props.content?.testimonials?.text && (
          <p className="section-headline-small" dangerouslySetInnerHTML={{ __html: props.content.testimonials.text }} />
        )}
        <div className="testimonials-slider-container">
          <div className="testimonials-slider-content" style={containerStyle}>
            {props.content?.testimonials?.elements?.map((element, index) => (
              <div
                key={`testimonial-container-${index}`}
                className="testimonial-container"
                style={{
                  background: bullets[index % 3].background,
                }}
                onClick={() => clickHandler(index)}
                role="button"
                ref={index === 0 ? containerRef : null}
              >
                <div className="testimonial-image">
                  <Img
                    fluid={element.person_image.childImageSharp.fluid}
                    alt={element.person_name}
                    style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', height: '100%' }}
                  />
                </div>
                <div className="testimonial-text">
                  <div className="testimonial-text-quote">
                    <div
                      className="testimonial-text-quote  testimonial-text-quote-left"
                      style={{ color: bullets[index % 3].color }}
                    >
                      <span>"</span>
                    </div>
                    <div className="testimonial-text-quote-cite">
                      <span dangerouslySetInnerHTML={{ __html: element.text }} />
                    </div>
                    <div
                      className="testimonial-text-quote  testimonial-text-quote-right"
                      style={{ color: bullets[index % 3].color }}
                    >
                      <span>"</span>
                    </div>
                  </div>
                  <div className="testimonial-text-person">
                    {element.person_name} -{' '}
                    <a href={element.company_url} target="_blank" rel="noreferrer">
                      {element.company_name}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="testimonials-slider-selector">
            {props.content?.testimonials?.elements?.map((element, index) => (
              <div
                key={index}
                role="button"
                onClick={() => clickHandler(index)}
                className={
                  index === activeIndex
                    ? 'testimonials-slider-selector-active'
                    : 'testimonials-slider-selector-inactive'
                }
              />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TestimonialsSummary;
