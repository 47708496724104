import React from 'react';
import { Container } from 'react-bootstrap';

import ClientItem from './ClientItem/ClientItem';

import './ClientSummary.css';

import EWELogo from '../../images/clients/ewe.svg';
import EWEGoLogo from '../../images/clients/ewe-go-black.svg';
import NMCLogo from '../../images/clients/nmc.svg';
import RWLogo from '../../images/clients/rechnungswunder.svg';
import ReadyplaceLogo from '../../images/clients/readyplace.png';
import HerodikosLogo from '../../images/clients/herodikos.svg';
import SHMLogo from '../../images/clients/sharemagazines.png';

const ClientsSummary = (props) => {
  const clients = [
    {
      name: 'EWE AG',
      website: 'https://www.ewe.de',
      logo: EWELogo,
    },
    {
      name: 'EWE Go GmbH',
      website: 'https://www.ewe-go.de/',
      logo: EWEGoLogo,
    },
    {
      name: 'New Media Company GmbH & Co. KG',
      website: 'https://www.newmediacompany.de',
      logo: NMCLogo,
    },
    {
      name: 'RechnungsWunder UG',
      website: 'https://rechnungswunder.de',
      logo: RWLogo,
    },
    {
      name: 'readyplace GmbH',
      website: 'https://www.readyplace.net',
      logo: ReadyplaceLogo,
    },
    {
      name: 'Herodikos GmbH',
      website: 'https://herodikos.de',
      logo: HerodikosLogo,
    },
    {
      name: 'sharemagazines GmbH',
      website: 'https://www.sharemagazines.de',
      logo: SHMLogo,
    },
  ];

  return (
    <section id="clients" className="section-content accent-soft">
      <Container fluid>
        <h2 className="clients-headline" dangerouslySetInnerHTML={{ __html: props.content.customers.headline }} />
        {props.content?.customers?.text && (
          <p
            className="section-headline-small clients-description"
            dangerouslySetInnerHTML={{ __html: props.content.customers.text }}
          />
        )}

        <div className="client-container">
          {clients.map((client) => (
            <ClientItem key={client.name} name={client.name} url={client.website} logo={client.logo} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ClientsSummary;
