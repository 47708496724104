import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import Landing from '../components/Landing/Landing';
import ServiceSummary from '../components/ServiceSummary/ServiceSummary';
import AboutSummary from '../components/AboutSummary/AboutSummary';
import ClientsSummary from '../components/ClientsSummary/ClientsSummary';
import ContactForm from '../components/ContactForm/ContactForm';
import ContactOffice from '../components/ContactOffice/ContactOffice';
import TestimonialsSummary from '../components/TestimonialsSummary/TestimonialsSummary';
import BlogSummary from '../components/BlogSummary/BlogSummary';

import './index.css';

import '../fonts/Lato-Regular.ttf';
import '../fonts/Lato-Bold.ttf';
import '../fonts/Montserrat-Italic.ttf';
import '../fonts/Montserrat-Regular.ttf';
import '../fonts/Montserrat-SemiBold.ttf';
import '../fonts/Montserrat-SemiBoldItalic.ttf';
import '../fonts/Roboto-Regular.ttf';
import '../fonts/Roboto-Bold.ttf';
import '../fonts/WorkSans-Bold.ttf';
import '../fonts/WorkSans-Regular.ttf';

const IndexPage = ({ data }) => {
  const intl = useIntl();
  const content = data.content.nodes[0].frontmatter;
  const services = data.services.nodes;

  return (
    <Layout accentHeader={true}>
      <SEO title={content.meta.seo_title} description={content.meta.seo_description} lang={intl.locale} />

      <Landing content={content} />
      <ClientsSummary content={content} />
      <ServiceSummary content={content} services={services} />
      <AboutSummary content={content} />
      <TestimonialsSummary content={content} />
      <BlogSummary content={content} newestPosts={data.newestPosts} />
      <ContactForm content={content} />
      <ContactOffice content={content} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query($language: String!) {
    newestPosts: allMarkdownRemark(
      filter: { collection: { eq: "blog" }, fields: { published: { eq: true }, language: { eq: $language } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 100)
        fields {
          slug
        }
        frontmatter {
          image {
            childImageSharp {
              fluid(maxWidth: 512, maxHeight: 384) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          abstract
          dateDe: date(formatString: "DD. MMMM YYYY", locale: "de_DE")
          dateEn: date(formatString: "MMMM DD, YYYY", locale: "en_US")
        }
      }
    }
    services: allMarkdownRemark(
      filter: { collection: { eq: "pages" }, folder: { eq: "services" }, fields: { language: { eq: $language } } }
    ) {
      nodes {
        frontmatter {
          meta {
            name
            slug
            order
          }
        }
      }
    }
    content: allMarkdownRemark(
      filter: { collection: { eq: "pages" }, folder: { eq: "index" }, fields: { language: { eq: $language } } }
    ) {
      nodes {
        frontmatter {
          meta {
            name
            slug
            seo_title
            seo_description
          }
          hero {
            headline
            text
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            image_tablet {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          customers {
            headline
            text
          }
          services {
            headline
            text
            elements {
              name
              text
              color
              background
              image {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              icon {
                publicURL
              }
            }
          }
          usp {
            headline
            text
            elements {
              name
              text
              image {
                publicURL
                extension
                childImageSharp {
                  fluid(maxWidth: 512) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          testimonials {
            headline
            text
            elements {
              text
              person_name
              person_image {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              company_name
              company_url
            }
          }
          blog {
            headline
            text
          }
          contact {
            headline
            text
          }
          office {
            headline
            text
          }
        }
      }
    }
  }
`;
